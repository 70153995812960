import // useGetBusinessEntityDetailsQuery,
"../../../../graphql/generated";
import { useDispatch } from "react-redux";
import { ShipmentLocationInputData } from "../../../../redux/slices/Types";
import ReceiverForm, { ReceiverFormProps } from "./ReceiverForm";
import { editShipmentLocationInput } from "../../../../redux/slices/shipment/ShipmentForm.slice";

type ReceiverContainerProps = Omit<
  ReceiverFormProps,
  | "onChange"
  | "storageFacilities"
  | "shipmentLocation"
  | "onRequestBestBuyRanking"
> & {
  receiver: ShipmentLocationInputData;
  onChange?: (receiver: ShipmentLocationInputData) => void;
};

export default function ReceiverFormContainer({
  receiver: receiverLocation,
  onChange,
  ...props
}: ReceiverContainerProps) {
  const dispatch = useDispatch();

  const handleChange = async (
    updatedReceiverLocation: ShipmentLocationInputData
  ) => {
    if (onChange) {
      onChange(updatedReceiverLocation);
    } else {
      dispatch(
        editShipmentLocationInput({
          location: updatedReceiverLocation,
        })
      );
    }
  };

  return (
    <div>
      <ReceiverForm
        shipmentLocation={receiverLocation}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
}
