import {
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {
  BillingMethod,
  BillingRate,
  BillingRateParameterType,
  BillingRuleType,
} from "../../../graphql/generated";
import billingMethodLabel from "../../../utils/labels/billingRule/billingMethodLabel";
import LocaleProvider from "../../../providers/LocaleProvider";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import RateParameterForm from "./RateParameterForm";

type RateFormProps = {
  rate: BillingRate;
  onChange: (billingRate: BillingRate) => void;
  ruleType: BillingRuleType;
};
const billingMethodOrder = [
  BillingMethod.FlatFee,
  BillingMethod.PerMile,
  BillingMethod.PerEmptyMile,
  BillingMethod.PerMileWithDeadhead,
  BillingMethod.PerKm,
  BillingMethod.PerEmptyKm,
  BillingMethod.PerKmWithDeadhead,
  BillingMethod.PerUnit,
  BillingMethod.PerKg,
  BillingMethod.PerPound,
  BillingMethod.PerLiter,
  BillingMethod.PerGallon,
];

const rateParametersPerBillingMethod: Partial<
  Record<BillingMethod, BillingRateParameterType[]>
> = {
  [BillingMethod.PerUnit]: [BillingRateParameterType.GoodProfile],
  [BillingMethod.PerKg]: [BillingRateParameterType.GoodProfile],
  [BillingMethod.PerPound]: [BillingRateParameterType.GoodProfile],
  [BillingMethod.PerLiter]: [BillingRateParameterType.GoodProfile],
  [BillingMethod.PerGallon]: [BillingRateParameterType.GoodProfile],
  [BillingMethod.PerLocation]: [
    BillingRateParameterType.LocationType,
    BillingRateParameterType.FreeLocationCount,
  ],
};

const RateForm = ({ rate, onChange, ruleType }: RateFormProps) => {
  const { t } = useTranslation("billingRules");

  return (
    <Grid container direction="row" alignItems="center" spacing={1} flex={1}>
      <Grid item xs={12} md={6} lg={4}>
        <Select
          label="Billing method"
          size="small"
          value={rate.method}
          onChange={(event) => {
            const billingMethod = event.target.value as BillingMethod;
            const rateParameterTypes =
              rateParametersPerBillingMethod[billingMethod] || [];
            onChange({
              ...rate,
              method: billingMethod,
              parameters: rateParameterTypes.map((type) => ({
                type,
                value:
                  rate.parameters?.find((p) => p.type === type)?.value || null,
              })),
            });
          }}
          sx={{ display: "flex" }}
        >
          {sortBy(Object.values(BillingMethod), (method) =>
            billingMethodOrder.indexOf(method) === -1
              ? Infinity
              : billingMethodOrder.indexOf(method)
          )
            .filter((method) => {
              if (ruleType === BillingRuleType.Primary) {
                return (
                  method !== BillingMethod.PercentageOfLineHaul &&
                  method !== BillingMethod.PerDetentionHour
                );
              }
              return true;
            })
            .map((billingMethod) => (
              <MenuItem key={billingMethod} value={billingMethod}>
                <ListItemText primary={billingMethodLabel(billingMethod)} />
              </MenuItem>
            ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Stack spacing={1}>
          {rate.parameters?.map((parameter, index) => (
            <Stack direction="column">
              <Stack direction="row" alignItems="center" spacing={1}>
                <RateParameterForm
                  key={`condition-${index}`}
                  rateParameter={parameter}
                  onChange={(rateParameter) =>
                    onChange({
                      ...rate,
                      parameters: rate.parameters?.map((p, i) =>
                        i === index ? rateParameter : p
                      ),
                    })
                  }
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <TextField
          type="number"
          value={String(rate.amount) || ""}
          onChange={(event) => {
            onChange({
              ...rate,
              amount: parseFloat(event.target.value) || 0,
            });
          }}
          size="small"
          label={t("form.amount")}
          inputProps={{
            type: "number",
            min: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {rate.method === BillingMethod.PercentageOfLineHaul
                  ? "%"
                  : LocaleProvider.getCurrencySymbol()}
              </InputAdornment>
            ),
          }}
          sx={{ display: "flex" }}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={2}>
        <Stack spacing={1}>
          <TextField
            type="string"
            value={rate.label || ""}
            onChange={(event) => {
              onChange({
                ...rate,
                label: event.target.value,
              });
            }}
            size="small"
            label={t("form.chargeLabel", "Charge label")}
            sx={{ display: "flex" }}
          />
          <TextField
            type="string"
            value={rate.referenceNumber || ""}
            onChange={(event) => {
              onChange({
                ...rate,
                referenceNumber: event.target.value,
              });
            }}
            size="small"
            label={t("form.reference")}
            sx={{ display: "flex" }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default RateForm;
