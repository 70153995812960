import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import {
  FormDivider,
  MuiPhoneInput,
} from "../BusinessEntityForm/BusinessEntityForm";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  AssetStatus,
  BusinessEntityType,
  CustomFieldContext,
  DriverLicenseClassification,
  DriverLicenseEndorsement,
  DriverLicenseRestriction,
  GetBusinessEntityListQuery,
  GetDriverDetailsQuery,
  NewDriverInput,
} from "../../../graphql/generated";
import AddressInput from "../AddressInput";
import { v4 as uuid } from "uuid";
import { capitalize, intersection, isArray, mergeWith, omit } from "lodash";
import driverSchema from "./driverSchema";
import { addYears, isValid } from "date-fns";
import InfoBlock from "../../common/InfoBlock";
import { FileUpload } from "@mui/icons-material";
import DriverDocumentsTable from "../DriverDetails/DriverDocumentsTable";
import DocumentsUploadModal from "../../common/DocumentsUploadModal";
import { DatePicker } from "@mui/x-date-pickers";
import EnumSelect from "../../common/EnumSelect";
import StateSelect from "../../common/StateSelect";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { useLocation } from "react-router-dom";
import BusinessEntitySelectContainer from "../../shipment/ShipmentForm/BusinessEntitySelect";
import { useTranslation } from "react-i18next";
import ChipTagsInput from "../../common/ChipTagsInput/ChipTagsInput";
import CustomFieldsFormContainer from "../../extensions/CustomFieldsForm";
import GroupSelectContainer from "../GroupSelect";
import getChangedProperties from "../../../utils/common/getChangedProperties";
import { useConfirm } from "material-ui-confirm";

const ELD_NON_EDITABLE_FIELDS: (keyof NewDriverInput)[] = [
  "firstname",
  "lastname",
  "middlename",
  "status",
];
const ELD_DANGEROUSLY_EDITABLE_FIELDS: (keyof NewDriverInput)[] = [
  "license",
  "dateOfBirth",
];

export type FormDriver = NewDriverInput & GetDriverDetailsQuery["driverById"];

type DriverFormProps = {
  initialDriver?: FormDriver;
  saving: boolean;
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  onSave: (driver: NewDriverInput) => void;
};
type PartialDriverInput = Partial<NewDriverInput>;
type DeepPartialDriver = {
  [key in keyof PartialDriverInput]: Partial<PartialDriverInput[key]>;
};

type DriverUser = {
  _id: string;
  email?: string | null | undefined;
  username: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  roles: {
    id: string;
    name: string;
  }[];
};

const DriverForm = ({
  initialDriver,
  saving,
  domiciles,
  onSave,
}: DriverFormProps) => {
  const { t } = useTranslation(["common", "users"]);
  const { state } = useLocation();
  const existingUser: DriverUser | undefined | null = state?.user;
  const includeAdminRole: boolean | undefined | null = state?.includeAdminRole;
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<FormDriver> | null>(null);
  const [localDriver, setLocalDriver] = useState<FormDriver | null>(
    initialDriver ||
      (existingUser?.firstName && existingUser.lastName
        ? {
            firstname: existingUser.firstName,
            lastname: existingUser.lastName,
            _id: uuid(),
            phoneNumber: "",
            status: AssetStatus.Active,
            userId: existingUser._id,
            email: existingUser.email,
            username: existingUser.username,
          }
        : null)
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialDriver) => {
    setLocalDriver((localDriver) =>
      mergeWith({}, localDriver, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = driverSchema.validate(
      omit(localDriver, "domicileEntity"),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDriver]);

  const { cancelConfirm } = useConfirmBeforeLeave(localDriver);

  const isFieldDisabled = useCallback(
    (fieldName: keyof NewDriverInput) => {
      return (
        !!initialDriver?.eldId && ELD_NON_EDITABLE_FIELDS.includes(fieldName)
      );
    },
    [initialDriver]
  );

  const confirm = useConfirm();

  const onDangerousChangesSave = async () => {
    try {
      if (!initialDriver || !localDriver) {
        return;
      }
      const changedPropertiesDict = getChangedProperties(
        initialDriver,
        localDriver
      );
      const changedProperties = Object.keys(changedPropertiesDict);

      if (
        intersection(ELD_DANGEROUSLY_EDITABLE_FIELDS, changedProperties).length
      ) {
        await confirm({
          title: t("common:warning", "Warning"),
          description: t(
            "users:driver.thirdPartyChangesConfirmation",
            "You are about to edit fields that are managed by a third-party system. Are you sure you want to continue ?"
          ),
          confirmationButtonProps: {
            color: "error",
          },
          confirmationText: t("common:yes", "Yes"),
          cancellationText: t("common:error.noCancel", "No, Cancel"),
        });
      }
      onSave(omit(localDriver, "_id"));
    } catch (error) {
      // Submission is cancelled
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {initialDriver && initialDriver.eldId && (
          <Grid item sm={12}>
            <Alert severity="info">
              {t(
                "users:driver.thirdPartyWarning",
                "This driver is managed by a third-party service. Some fields will not be editable."
              )}
            </Alert>
          </Grid>
        )}
        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={capitalize(t("users:driver.details", "Driver Details"))}
          />
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <TextField
                required
                label={t("users:firstName", "First Name")}
                name="firstname"
                fullWidth
                value={localDriver?.firstname || ""}
                error={!!getFieldError("firstname")}
                helperText={getFieldError("firstname")}
                onChange={(event) => {
                  onChange({ firstname: event.target.value });
                }}
                disabled={isFieldDisabled("firstname")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label={t("users:middleName", "Middle Name")}
                name="middlename"
                fullWidth
                value={localDriver?.middlename || ""}
                error={!!getFieldError("middlename")}
                helperText={getFieldError("middlename")}
                onChange={(event) => {
                  onChange({ middlename: event.target.value });
                }}
                disabled={isFieldDisabled("middlename")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                required
                label={t("users:lastName", "Last Name")}
                name="lastname"
                fullWidth
                value={localDriver?.lastname || ""}
                error={!!getFieldError("lastname")}
                helperText={getFieldError("lastname")}
                onChange={(event) => {
                  onChange({ lastname: event.target.value });
                }}
                disabled={isFieldDisabled("lastname")}
              />
            </Grid>

            <Grid item sm={4}>
              <PhoneInput
                label={t("users:phoneNumber", "Phone Number")}
                name="phoneNumber"
                required
                fullWidth
                error={!!getFieldError("phoneNumber")}
                helperText={getFieldError("phoneNumber")}
                defaultCountry="US"
                value={localDriver?.phoneNumber || ""}
                onChange={(phoneNumber) => {
                  onChange({
                    phoneNumber: phoneNumber,
                  });
                }}
                inputComponent={MuiPhoneInput}
              />
            </Grid>
            <Grid item sm={4}>
              {/* <ErrorMessage message={getFieldError("domicile")} /> */}
              {/* <AddressInput
                label="Domicile"
                value={localDriver?.domicile || null}
                onChange={(domicile) => {
                  onChange({ domicile });
                }}
                disableDetails
                errors={{
                  label: getFieldError("domicile", true),
                }}
              /> */}

              <BusinessEntitySelectContainer
                businessEntityType={BusinessEntityType.Domicile}
                value={localDriver?.domicileId || null}
                onChange={(domicileId) => {
                  onChange({ domicileId: domicileId });
                }}
                inputProps={{
                  label: "Domicile",
                  error: !!getFieldError("domicileId"),
                  helperText: getFieldError("domicileId"),
                  size: "medium",
                  required: false,
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <EnumSelect
                label={t("users:driver.status.one", "Status")}
                name="status"
                fullWidth
                enumObject={AssetStatus}
                optionLabel={(status) => t(`common:statusTypes.${status}`)}
                error={!!getFieldError("status")}
                helperText={getFieldError("status")}
                value={localDriver?.status || AssetStatus.Active}
                onChange={(event, status) => {
                  if (!status) {
                    return;
                  }
                  onChange({
                    status,
                  });
                }}
                disabled={isFieldDisabled("status")}
              />
            </Grid>
            <Grid item sm={4}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    required
                    label={t("users:driver.dateOfBirth", "Date of birth")}
                    name="dateOfBirth"
                    fullWidth
                    error={!!getFieldError("dateOfBirth")}
                    helperText={getFieldError("dateOfBirth")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                maxDate={addYears(new Date(), -16)}
                minDate={addYears(new Date(), -100)}
                value={localDriver?.dateOfBirth || null}
                onChange={(dateString) => {
                  if (dateString && isValid(dateString)) {
                    onChange({ dateOfBirth: new Date(dateString) });
                  }
                }}
                openTo={"year"}
              />
            </Grid>
            <Grid item sm={4}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    required
                    label={t(
                      "users:driver.dateOfPhysicalExamination",
                      "Date of physical examination"
                    )}
                    name="dateOfPhysicalExamination"
                    fullWidth
                    error={!!getFieldError("dateOfPhysicalExamination")}
                    helperText={getFieldError("dateOfPhysicalExamination")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={localDriver?.dateOfPhysicalExamination || null}
                onChange={(dateString) => {
                  if (dateString && isValid(dateString)) {
                    onChange({
                      dateOfPhysicalExamination: new Date(dateString),
                    });
                  }
                }}
              />
            </Grid>

            <Grid item sm={4}>
              <ChipTagsInput
                value={localDriver?.tags || []}
                onChange={(tags) => {
                  onChange({ tags });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <GroupSelectContainer
                value={localDriver?.groupIds || []}
                onChange={(groupIds) => {
                  onChange({ groupIds });
                }}
              />
            </Grid>

            <Grid item sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!localDriver?.isOwnerOperator}
                    onChange={(event, checked) => {
                      onChange({
                        isOwnerOperator: checked,
                      });
                    }}
                  />
                }
                label={t("users:driver.isOwnerOperator", "Is owner operator")}
                name="isOwnerOperator"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={capitalize(
              t("users:driver.license.details", "License Details")
            )}
          />
          <ErrorMessage message={getFieldError("license")} />
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <TextField
                required
                label={capitalize(
                  t("users:driver.license.number", "License number")
                )}
                name="licenseNumber"
                fullWidth
                value={localDriver?.license?.licenseNumber || ""}
                error={!!getFieldError("license.licenseNumber")}
                helperText={getFieldError("license.licenseNumber")}
                onChange={(event) => {
                  onChange({ license: { licenseNumber: event.target.value } });
                }}
              />
            </Grid>
            <Grid item sm={4} />
            <Grid item sm={4} />

            <Grid item sm={4}>
              <StateSelect
                required
                label={capitalize(
                  t("users:driver.license.issuedState", "License issued state")
                )}
                name="licenseIssuedState"
                fullWidth
                value={localDriver?.license?.issuedState || ""}
                error={!!getFieldError("license.issuedState")}
                helperText={getFieldError("license.issuedState")}
                onChange={(event) => {
                  onChange({ license: { issuedState: event.target.value } });
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    required
                    label={capitalize(
                      t(
                        "users:driver.license.issuedDate",
                        "License issued date"
                      )
                    )}
                    name="licenseIssuedDate"
                    fullWidth
                    error={!!getFieldError("license.issuedDate")}
                    helperText={getFieldError("license.issuedDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={localDriver?.license?.issuedDate || null}
                onChange={(dateString) => {
                  if (dateString && isValid(dateString)) {
                    onChange({
                      license: { issuedDate: new Date(dateString) },
                    });
                  }
                }}
                maxDate={new Date()}
              />
            </Grid>
            <Grid item sm={4}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    required
                    label={capitalize(
                      t(
                        "users:driver.license.expirationDate",
                        "License expiry date"
                      )
                    )}
                    name="licenseExpiryDate"
                    fullWidth
                    error={!!getFieldError("license.expiryDate")}
                    helperText={getFieldError("license.expiryDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={localDriver?.license?.expiryDate || null}
                onChange={(dateString) => {
                  if (dateString && isValid(dateString)) {
                    onChange({
                      license: { expiryDate: new Date(dateString) },
                    });
                  }
                }}
              />
            </Grid>

            <Grid item sm={4}>
              <EnumSelect
                enumObject={DriverLicenseClassification}
                required
                label={t("users:driver.classification", "Classification")}
                name="classification"
                fullWidth
                value={localDriver?.license?.classification || null}
                error={!!getFieldError("license.classification")}
                helperText={getFieldError("license.classification")}
                onChange={(event, classification) => {
                  onChange({ license: { classification } });
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <EnumSelect
                enumObject={DriverLicenseEndorsement}
                label={t("users:driver.endorsements", "Endorsements")}
                name="endorsements"
                fullWidth
                multiple
                value={localDriver?.license?.endorsements || []}
                error={!!getFieldError("license.endorsements")}
                helperText={getFieldError("license.endorsements")}
                onChange={(event, endorsements) => {
                  onChange({ license: { endorsements } });
                }}
                clearable
                withNoneOption
              />
            </Grid>
            <Grid item sm={4}>
              <EnumSelect
                enumObject={DriverLicenseRestriction}
                label={t("users:driver.restriction", "Restriction")}
                name="restriction"
                fullWidth
                value={localDriver?.license?.restriction || null}
                error={!!getFieldError("license.restriction")}
                helperText={getFieldError("license.restriction")}
                onChange={(event, restriction) => {
                  onChange({ license: { restriction } });
                }}
                clearable
                withNoneOption
              />
            </Grid>
            <Grid item sm={12}>
              <ErrorMessage message={getFieldError("license.address")} />
              <AddressInput
                value={localDriver?.license?.address || null}
                onChange={(address) => {
                  onChange({ license: { address } });
                }}
                required
                disableAutocomplete
                errors={{
                  label: getFieldError("license.address.label"),
                  coordinates: getFieldError("license.address.coordinates"),
                  line1: getFieldError("license.address.line1"),
                  line2: getFieldError("license.address.line2"),
                  postalCode: getFieldError("license.address.postalCode"),
                  state: getFieldError("license.address.state"),
                  city: getFieldError("license.address.city"),
                  country: getFieldError("license.address.country"),
                }}
                horizontal
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={t("users:driver.medicalDetails", "Medical Details")}
          />
          <ErrorMessage message={getFieldError("medical")} />
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <TextField
                label={t("users:driver.medicalNumber", "Medical number")}
                name="medicalNumber"
                fullWidth
                value={localDriver?.medical?.number || ""}
                error={!!getFieldError("medical.number")}
                helperText={getFieldError("medical.number")}
                onChange={(event) => {
                  onChange({ medical: { number: event.target.value } });
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label={t("users:driver.issuedDate", "Issued date")}
                    name="medicalIssuedDate"
                    fullWidth
                    error={!!getFieldError("medical.issuedDate")}
                    helperText={getFieldError("medical.issuedDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={localDriver?.medical?.issuedDate || null}
                onChange={(dateString) => {
                  if (dateString && isValid(dateString)) {
                    onChange({
                      medical: { issuedDate: new Date(dateString) },
                    });
                  }
                }}
                maxDate={new Date()}
              />
            </Grid>
            <Grid item sm={4}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label={t("users:driver.expiryDate", "Expiry date")}
                    name="medicalExpiryDate"
                    fullWidth
                    error={!!getFieldError("medical.expiryDate")}
                    helperText={getFieldError("medical.expiryDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                value={localDriver?.medical?.expiryDate || null}
                onChange={(dateString) => {
                  if (dateString && isValid(dateString)) {
                    onChange({
                      medical: { expiryDate: new Date(dateString) },
                    });
                  }
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label={t("users:driver.restriction", "Restriction")}
                name="medical.restriction"
                fullWidth
                value={localDriver?.medical?.restriction || ""}
                error={!!getFieldError("medical.restriction")}
                helperText={getFieldError("medical.restriction")}
                onChange={(event) => {
                  onChange({ medical: { restriction: event.target.value } });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("users:authentication", "Authentication")}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label={t("users:email", "Email")}
                name="email"
                autoComplete="off"
                fullWidth
                value={localDriver?.email || ""}
                error={!!getFieldError("email") || !!getFieldError("username")}
                helperText={getFieldError("email") || getFieldError("username")}
                onChange={(event) => {
                  onChange({
                    email: event.target.value,
                    username: event.target.value,
                  });
                }}
              />
            </Grid>
            {/* <Grid item sm={12}>
              <TextField
                required={!!includeAdminRole}
                label="Username"
                fullWidth
                autoComplete="off"
                value={localDriver?.username || ""}
                error={!!getFieldError("username")}
                helperText={getFieldError("username")}
                onChange={(event) => {
                  onChange({ username: event.target.value });
                }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                required={!!includeAdminRole}
                label={t("users:password", "Password")}
                name="password"
                type="password"
                autoComplete="new-password"
                fullWidth
                value={localDriver?.password || ""}
                error={!!getFieldError("password")}
                helperText={
                  getFieldError("password") ||
                  (!!initialDriver?.email && initialDriver.userId
                    ? t(
                        "users:passwordResetInstruction",
                        "You can only reset the password in the driver details page"
                      )
                    : t(
                        "users:passwordResetDescription",
                        "User will need to change his password on his next login"
                      ))
                }
                onChange={(event) => {
                  onChange({ password: event.target.value });
                }}
                disabled={!!initialDriver?.email}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={6}>
          <FormDivider variant="fullWidth" text={t("common:customFields")} />

          <CustomFieldsFormContainer
            context={CustomFieldContext.Driver}
            customFields={localDriver?.customFields || []}
            onChange={(customFields) => {
              onChange({ customFields });
            }}
          />
        </Grid>

        <Grid item sm={12}>
          <FormDivider
            variant="fullWidth"
            text={capitalize(t("users:driver.document.many", "Documents"))}
          />
          <ErrorMessage message={getFieldError("documents")} />

          <InfoBlock
            title=""
            action={
              <Button
                variant="outlined"
                onClick={() => setIsDocumentModalOpen(true)}
                startIcon={<FileUpload />}
              >
                {t("users:driver.document.uploadOne", "Upload Document")}
              </Button>
            }
          >
            <DriverDocumentsTable
              documents={
                localDriver?.documents?.map((doc) => ({
                  ...doc,
                  // if the document is new, we need to generate an id
                  // without putting it in localDriver.documents
                  // to avoid Cast error on localDriver submission
                  _id: doc._id || uuid(),
                })) || []
              }
              onDelete={(id) => {
                onChange({
                  documents: (localDriver?.documents || []).filter(
                    (doc) => doc._id !== id
                  ),
                });
              }}
            />
          </InfoBlock>
          <DocumentsUploadModal
            isOpen={isDocumentModalOpen}
            onSubmit={(documents) => {
              setIsDocumentModalOpen(false);
              onChange({
                documents: [
                  ...(localDriver?.documents || []),
                  ...documents.map((doc) => ({
                    name: doc.name,
                    url: doc.url,
                  })),
                ],
              });
            }}
            onCancel={() => {
              setIsDocumentModalOpen(false);
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (validate()) {
              if (
                !!includeAdminRole &&
                !initialDriver &&
                !(localDriver?.password && localDriver?.username)
              ) {
                alert(
                  "Driver admin credentials are required (username and password)"
                );
                return;
              }
              cancelConfirm();
              if (!initialDriver || !localDriver?.eldId) {
                onSave(omit(localDriver, "_id"));
              } else {
                onDangerousChangesSave();
              }
            }
          }}
          id="saveDriverButton"
        >
          {t("common:save", "Save")}
        </Button>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default DriverForm;
