import { Delete, DragHandle } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { arrayMoveImmutable } from "array-move";
import { sortBy, without } from "lodash";
import { useEffect, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { v4 as uuid } from "uuid";
import {
  BillingMethod,
  BillingRule,
  BillingRuleConditionOperator,
  BillingRuleConditionTarget,
  BillingRuleType,
  GetCustomerDetailsQuery,
} from "../../../graphql/generated";
import { hasValue } from "../../../utils/form/hasValue";
import LabeledAddButton from "../../common/LabeledAddButton";
import BillingRuleForm from "../BillingRuleForm";
import { useTranslation } from "react-i18next";

export type BillingRuleItem = Omit<BillingRule, "condition">;

type BillingRulesEditorProps = {
  initialBillingRules: BillingRuleItem[];
  saving: boolean;
  onSave: (billingRules: BillingRuleItem[]) => void;
  customer: GetCustomerDetailsQuery["customerById"];
};

const BillingRulesEditor = ({
  initialBillingRules,
  saving,
  onSave,
  customer,
}: BillingRulesEditorProps) => {
  const [billingRules, setBillingRules] = useState(initialBillingRules);
  useEffect(() => {
    setBillingRules(initialBillingRules);
  }, [initialBillingRules]);

  const primaryBillingRules = sortBy(
    billingRules.filter(
      (billingRule) => billingRule.type === BillingRuleType.Primary
    ),
    (billingRule, index) =>
      hasValue(billingRule.priority) ? billingRule.priority : index
  );

  const accessorialBillingRules = billingRules.filter(
    (billingRule) => billingRule.type === BillingRuleType.Accessorial
  );
  const { t } = useTranslation("billingRules");
  return (
    <Box>
      <Typography variant="h6">{t("lineHaulBillingRules")}</Typography>
      <Typography variant="subtitle1">
        {t("lineHaulBillingRulesDescription")}
        {/* Line haul billing rules are applied in the order they are listed. The
        first rule that matches the condition will be applied. You can reorder
        the rules by dragging the handle on the left. */}
      </Typography>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <SortableBillingRulesList
          billingRules={primaryBillingRules}
          customer={customer}
          onChange={(rules) => {
            setBillingRules([...rules, ...accessorialBillingRules]);
          }}
          onSortEnd={({ oldIndex, newIndex }) => {
            setBillingRules([
              ...arrayMoveImmutable(
                primaryBillingRules,
                oldIndex,
                newIndex
              ).map((billingRule, index) => ({
                ...billingRule,
                priority: index,
              })),
              ...accessorialBillingRules,
            ]);
          }}
          useDragHandle
        />
        <LabeledAddButton
          label={t("addBillingRule")}
          onClick={() => {
            setBillingRules(
              billingRules.concat({
                _id: "NEW-" + uuid(),
                conditions: [
                  {
                    target: BillingRuleConditionTarget.TrailerType,
                    operator: BillingRuleConditionOperator.Equals,
                    value: null,
                  },
                ],
                method: BillingMethod.PerMile,
                amount: 0,
                customer: null,
                type: BillingRuleType.Primary,
                label: "",
                referenceNumber: "",
                priority: billingRules.length,
              })
            );
          }}
        />
      </Stack>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t("accessorialBillingRules")}
      </Typography>
      <Stack spacing={2}>
        {accessorialBillingRules.map((billingRule) => (
          <Card>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <BillingRuleForm
                customer={customer}
                key={billingRule._id}
                billingRule={billingRule}
                onChange={(updatedBillingRule) => {
                  setBillingRules(
                    billingRules.map((billingRule) =>
                      billingRule._id === updatedBillingRule._id
                        ? updatedBillingRule
                        : billingRule
                    )
                  );
                }}
              />

              <IconButton
                onClick={() => {
                  setBillingRules(without(billingRules, billingRule));
                }}
              >
                <Delete />
              </IconButton>
            </CardContent>
          </Card>
        ))}

        <LabeledAddButton
          label={t("addBillingRule")}
          onClick={() => {
            setBillingRules(
              billingRules.concat({
                _id: "NEW-" + uuid(),
                conditions: [
                  {
                    target: BillingRuleConditionTarget.TrailerType,
                    operator: BillingRuleConditionOperator.Equals,
                    value: null,
                  },
                ],
                method: BillingMethod.PerMile,
                amount: 0,
                customer: null,
                type: BillingRuleType.Accessorial,
                label: "",
                referenceNumber: "",
                priority: billingRules.length,
              })
            );
          }}
        />
      </Stack>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          disabled={saving}
          onClick={() => {
            onSave(billingRules);
          }}
        >
          {t("saveChanges")}
        </Button>
      </Box>
    </Box>
  );
};

const SortableBillingRulesList = SortableContainer(
  ({
    billingRules,
    customer,
    onChange,
  }: {
    billingRules: BillingRuleItem[];
    customer: GetCustomerDetailsQuery["customerById"];
    onChange: (billingRules: BillingRuleItem[]) => void;
  }) => {
    return (
      <Stack spacing={2}>
        {billingRules.map((billingRule, index) => (
          <>
            <SortableBillingRuleItem
              billingRule={billingRule}
              customer={customer}
              onChange={(updatedBillingRule) => {
                onChange(
                  billingRules.map((billingRule) =>
                    billingRule._id === updatedBillingRule._id
                      ? updatedBillingRule
                      : billingRule
                  )
                );
              }}
              onDelete={() => {
                onChange(without(billingRules, billingRule));
              }}
              key={billingRule._id}
              index={index}
              billingRuleIndex={index}
            />
            <Divider />
          </>
        ))}
      </Stack>
    );
  }
);

const SortableBillingRuleItem = SortableElement(
  ({
    billingRule,
    customer,
    onChange,
    onDelete,
    billingRuleIndex,
  }: {
    billingRule: BillingRuleItem;
    customer: GetCustomerDetailsQuery["customerById"];
    onChange: (billingRule: BillingRuleItem) => void;
    onDelete: () => void;
    billingRuleIndex: number;
  }) => {
    return (
      <Card>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <BillingRuleItemDragHandle />
          <Typography
            sx={{
              pt: 1.4,
            }}
            variant="body2"
            color="primary"
            fontWeight="bold"
          >
            &nbsp;{billingRuleIndex + 1}.&nbsp;
          </Typography>
          <BillingRuleForm
            customer={customer}
            key={billingRule._id}
            billingRule={billingRule}
            onChange={onChange}
          />

          <IconButton
            onClick={() => {
              onDelete();
            }}
          >
            <Delete />
          </IconButton>
        </CardContent>
      </Card>
    );
  }
);

const BillingRuleItemDragHandle = SortableHandle(() => (
  <Box
    className="drag-handle"
    sx={{
      cursor: "move",
      pt: 1.1,
    }}
  >
    <DragHandle />
  </Box>
));

export default BillingRulesEditor;
